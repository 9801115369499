export const languageElements = {
  items: {
    "field-login": {
      hu: "Az ön e-mail címe:",
      de: "Ihre E-Mail-Adresse:",
      en: "Your email adress:",
    },

    "field-pass": {
      hu: "Jelszó:",
      de: "Passwort:",
      en: "Password:",
    },

    "btn-login": {
      hu: "Belépés",
      de: "Login",
      en: "Login",
    },

    "btn-loginAsGuest": {
      hu: "Vendég",
      de: "Als Gast einloggen",
      en: "Login as guest",
    },

    "validation-required": {
      hu: "Nem elhagyható adat",
      de: "Dateneingabe erforderlich",
      en: "Data is required",
    },

    "password-forgotten": {
      hu: "Ha elfelejtette a jelszavát, kattintson ide.",
      de: "Wenn Sie Ihr Passwort vergessen haben, klicken Sie hier.",
      en: "If you have forgotten your password, click here.",
    },

    "invalid-password": {
      hu: "Hibás bejelentkezési adatok",
      de: "Falsche Angaben",
      en: "Incorrect login data",
    },

    "result-nok": {
      hu: "Hibás bejelentkező adatok",
      de: "Inkorrekte Angaben",
      en: "Incorrect login data",
    },

    "result-no-response": {
      hu: "A szerviz nem reagál. Kérem próbálja később.",
      de: "Service antwortet nicht. Bitte versuchen Sie es später noch einmal.",
      en: "The service does not respond. Please try again later.",
    },

    "message-password-forgotten-email-sent": {
      hu: "Új jelszó megadásához az email-t elküldtük.",
      de: "Um ein neues Passwort einzugeben, wurde die E-Mail verschickt.",
      en: "To enter a new password, the email has been sent.",
    },

    "message-recaptcha-validation-failed": {
      hu: "Hibás CAPTCHA. Próbálja ismét.",
      de: "Falsches CAPTCHA. Versuchen Sie es erneut.",
      en: "Incorrect CAPTCHA. Try again.",
    },

    "message-password-forgotten-email-invalid": {
      hu: "Kérem adja meg az e-mail címét",
      de: "Bitte geben Sie Ihre E-Mail Adresse ein",
      en: "Please enter your e-mail address",
    },
  },
};
