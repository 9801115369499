import { Component } from "react";
import downloadIcon from "./file_download_black_24dp.svg";
import xIcon from "./x.svg";
import "./ReportInvoicesFunctionCell.scss";

export default class ReportInvoicesFunctionCell extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
    this.status = '';
  }

  renderStatus(newStatus) {
    this.status = newStatus;
    this.forceUpdate();
  }

  btnClickedHandler() {
    this.props.clicked(this.props.value, this.renderStatus.bind(this));
  }

  render() {
    if (this.props.data._origData.hasDocuments !== '0') {
      switch (this.status) {
        case 'LOADING':
          return (
            <div className="reportInvoices-FunctionCell-download">
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          );

        case 'ERROR':
          return (
            <div className="reportInvoices-FunctionCell-download">
              <img
                src={xIcon}
                onClick={this.btnClickedHandler}
                alt="download"
              />
            </div>
          )

        default:
          return (
            <div className="reportInvoices-FunctionCell-download">
              <img
                src={downloadIcon}
                onClick={this.btnClickedHandler}
                alt="download"
              />
            </div>
          );
      }
    }

    return (
      <div className="reportStocks-FunctionCell-err">
      </div>
    )
  }
}
