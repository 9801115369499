export const languageElements = {
  items: {
    title1: {
      hu: "Yusen Logistics (Hungary) Kft.",
      de: "Yusen Logistics (Hungary) Kft.",
      en: "Yusen Logistics (Hungary) Kft.",
    },

    title2: {
      hu: "Branch Office Koper",
      de: "Branch Office Koper",
      en: "Branch Office Koper",
    },

    info1: {
      hu: "Ferrarska ulica 30",
      de: "Ferrarska ulica 30",
      en: "Ferrarska ulica 30",
    },

    info2: {
      hu: "6000 Koper-Capodistria",
      de: "6000 Koper-Capodistria",
      en: "6000 Koper-Capodistria",
    },

    info3: {
      hu: "Slovenia",
      de: "Slovenia",
      en: "Slovenia",
    },

    info4: {
      hu: "Tel: +386 5 9076760",
      de: "Tel: +386 5 9076760",
      en: "Tel: +386 5 9076760",
    },

    info5: {
      hu: "E-mail: ",
      de: "E-mail: ",
      en: "E-mail: ",
    },

    email: {
      hu: "slovenia@si.yusen-logistics.com",
      de: "slovenia@si.yusen-logistics.com",
      en: "slovenia@si.yusen-logistics.com",
    },
  },
};
