import { memo, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function ReCaptcha(props) {
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(verifyReCaptcha, [props.verify]);

    async function verifyReCaptcha() {
        if (!props.verify) {
            return;
        }
        if (!executeRecaptcha) {
            return;
        }

        try {
            const token = await executeRecaptcha('SUBMIT');
            props.onVerified(token);
        } catch (error) {
            props.onFailed();
            console.log('+++ ERRORRRRRR', error)
            console.error('invalid ReCAPTCHA')
        }
    }

    return (
        <div className="reCAPTCHA">
        </div>
    );
}

export default memo(ReCaptcha);
