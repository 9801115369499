export const languageElements = {
  items: {
    "flush-filter": {
      hu: "Adatok előszűrése",
      de: "Daten filtern",
      en: "Filtering data",
    },

    "flush-data": {
      hu: "Raktárkészletem",
      de: "Bestandsliste",
      en: "List of stocks",
    },
    "field-Expire_Date": {
      hu: "Lejárat",
      de: "MHD",
      en: "Expiration Date",
    },
    "field-externalSystemId": {
      hu: "External System ID",
      de: "External System ID",
      en: "External System ID",
    },

    "field-PartnerId": {
      hu: "Partner ID",
      de: "Partner ID",
      en: "Partner ID",
    },
    "field-Items_No": {
      hu: "Cikkszám",
      de: "Artikelnummer",
      en: "Item number",
    },
    "field-Items_Description_1": {
      hu: "Áru megnevezése",
      de: "Bezeichnung",
      en: "Description",
    },
    "field-Items_Description_2": {
      hu: "Áru megnevezése 2",
      de: "Bezeichnung 2",
      en: "Description 2",
    },
    "field-ProdDate": {
      hu: "Gyártási dátum",
      de: "Produktionsdatum",
      en: "Production date",
    },
    "field-LOT_1": {
      hu: "Sarzs",
      de: "Partie",
      en: "LOT number",
    },
    "field-Packaging": {
      hu: "Csomagolás",
      de: "Verpackung",
      en: "Packaging",
    },
    "field-Price_Unit": {
      hu: "Egység",
      de: "Einheit",
      en: "Unit",
    },
    "field-Status": {
      hu: "Állapot",
      de: "Status",
      en: "Status",
    },
    "field-Weight": {
      hu: "Súly",
      de: "Gewicht",
      en: "Weight",
    },
    "field-Weight_Net": {
      hu: "Nettó súly",
      de: "Netto Gewicht",
      en: "Net weight",
    },
    "field-Stock_Available": {
      hu: "Szabad készlet",
      de: "Freier Bestand",
      en: "Free stock",
    },
    "field-Stock_Reserved": {
      hu: "Foglalt készlet",
      de: "Reservierter Bestand",
      en: "Stock reserved",
    },
    "field-Volume": {
      hu: "Térfogat",
      de: "Volumen",
      en: "Volume",
    },
    "field-Warehouse": {
      hu: "Raktár",
      de: "Lagerort",
      en: "Warehouse",
    },
    "status-normal": {
      hu: "Normál",
      de: "Normal",
      en: "normal",
    },
    "status-damaged": {
      hu: "Sérült",
      de: "Beschädigt",
      en: "Damaged",
    },
  },
};
