export const languageElements = {
  items: {
    title1: {
      hu: "Yusen Logistics (Hungary) Kft.",
      de: "Yusen Logistics (Hungary) Kft.",
      en: "Yusen Logistics (Hungary) Kft.",
    },

    title2: {
      hu: "",
      de: "",
      en: "",
    },

    info1: {
      hu: "Europa u. 6.",
      de: "Europa u. 6.",
      en: "Europa u. 6.",
    },

    info2: {
      hu: "1239 Budapest",
      de: "1239 Budapest",
      en: "1239 Budapest",
    },

    info3: {
      hu: "Hungary",
      de: "Hungary",
      en: "Hungary",
    },

    info4: {
      hu: "Tel: +36 (1) 555 2500",
      de: "Tel: +36 (1) 555 2500",
      en: "Tel: +36 (1) 555 2500",
    },

    info5: {
      hu: "Fax: +36 (1) 555 2501",
      de: "Fax: +36 (1) 555 2501",
      en: "Fax: +36 (1) 555 2501",
    },

    info6: {
      hu: "E-mail: ",
      de: "E-mail: ",
      en: "E-mail: ",
    },

    email: {
      hu: "info@hu.yusen-logistics.com",
      de: "info@hu.yusen-logistics.com",
      en: "info@hu.yusen-logistics.com",
    }
  },
};
