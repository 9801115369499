import GridReport from "../GridReport/GridReport";
import { languageElements } from "./ReportInvoices-languageElements";
import ReportInvoicesFunctionCell from "./ReportInvoicesFunctionCell";
import DownloadManager from "../repository/fileStream/DownloadManager";

export default function ReportInvoices(props) {
  async function downloadFiles(invId, renderStatus) {
    renderStatus('LOADING');
debugger
    fetch(`${process.env.REACT_APP_API_BASE_URL}/docman/parent/files`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          token: props.loginData.getToken(),
          parenttable: 'INV',
          parentexternalsystemid: invId,
        },
      })

      .then((data) => {
        debugger
        if (data.status !== 200) {
          throw new Error('result-nok');
        }
        return data.json();
      })

      .then((jsonData) => {
        debugger
        jsonData.forEach(file => {
          const downloadManager = new DownloadManager(
            props.loginData,
            {
              fileId: file._id,
              fileName: file.origFileName,
            });

          downloadManager.downloadBlob();

          renderStatus('');
        });
      })

      .catch((error) => {
        debugger
        console.error('+++', error)

        renderStatus('ERROR');
      });
  }

  return (
    <div className="reportInvoices">
      <GridReport
        id="ReportInvoices01"
        dataEndpoint="data/get"
        language={props.language}
        loginData={props.loginData}
        report={{
          reportId: "ReportInvoices01",
          languageElements: languageElements,
          frameworkComponents: {
            ReportInvoicesFunctionCell,
          },
          cellRenderers: [
            {
              field: "hasDocuments",
              cellRenderer: "ReportInvoicesFunctionCell",
              cellRendererParams: {
                loginData: props.loginData,
                parentTable: 'INV',
                clicked: (invId, renderStatus) => downloadFiles(invId, renderStatus),
              },
            },
          ],
        }}
      />
    </div>
  );
}
